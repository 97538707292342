:root {
    --myovolt-purple: #9747ff;
    --myovolt-teal: #56c1ca;
    --myovolt-grey1: #dddddd;
    --myovolt-grey2: #aeaeae;

  }

  body {
    background: linear-gradient(to Top, #9747ff10 30%, #56c1ca20);
    height: 130vh;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
  }
  
  .bg-custom-purple {
    background-color: #9747ff;
  }
  
  .bg-custom-teal {
    background-color: #56c1ca;
  }

  .bg-custom-red {
    background-color: #F60E38;
  }

  .bg-schedule-c {
    background-color: #73E38C;
  }

  .bg-schedule-nc {
    background-color: #c9c9c9;
  }

  .myovolt-link {
    padding: 10px;
    position: relative;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
  }
  

  .selected {
    background-color: var(--myovolt-teal); /* Your selected background color */
    color: #ffffff; /* Selected text color */
  }

  .footer{
    color: var(--myovolt-grey2); /* Text color */
    background-color: #ffffff; /* Footer background color */
    padding: 20px; /* Space inside the footer */
    border-top: 2px solid #ffffff; /* Top border */
  }
  
  .customYAxis {
    /* Fixed width and styles for the Y-axis labels container */
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 10px; /* Spacing between labels and chart */
    padding-bottom: 24px;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-button {
    background-color: white; /* Green background */
    color: black; /* White text */
    padding: 10px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  .dropdown-menu {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-menu a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-menu a:hover {
    background-color: #f1f1f1
  }
  
  .dropdown:hover .dropdown-button {
    background-color: #f1f1f1;
  }
  
  .btn {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;
  }

  .btn-active {
    background-color: white;
    color: #9747ff;
    border: 1px solid #9747ff; 
  }

  .btn-active:hover {
    background-color: white;
    border: 1px solid #9747ff;
  }

  .btn-inactive {
    background-color: #eeeeee;
    color: black;
    border: 1px solid #eeeeee; 
  }

  .btn-inactive:hover {
    background-color: #eeeeee;
    border: 1px solid #eeeeee;
  }

  .table-responsive {
    max-height: 300px;
    overflow-y: auto;
    margin-top: 10px;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  .table th, .table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
  }

  .table th {
    background-color: #f2f2f2;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 17px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #9747ff;
  }

  input:checked + .slider:before {
    transform: translateX(13px);
  }

  /* Optional: to add shadow for better aesthetics */
  .slider:before {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .menu-horizontal {
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }

  .menu-horizontal li {
    margin: 0 10px;
    cursor: pointer;
  }
    